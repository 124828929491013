import React, { useEffect, useState } from "react";
import querystring from "querystring";

import { Container, Grid, Link, Message, Stack, Text } from "basis";

import AuthContext from "../core/AuthContext";
import { TRANSACTION_STATUS } from "../core/constants";
import { getOutcome } from "../layout/outcome";

const MESSAGE_MAP = {
  [TRANSACTION_STATUS.APPROVED_NO_CALLBACK]: {
    title: "Your payment was successful.",
    description: "However we can't contact the merchant.",
  },
  [TRANSACTION_STATUS.REJECTED_NO_CALLBACK]: {
    title: "Your payment was declined.",
    description: "However we can't contact the merchant.",
  },
  [TRANSACTION_STATUS.GATEWAY_ERROR]: {
    title: "Sorry, we could not complete your payment.",
    description: "Any amount charged to your account will be reversed.",
  },
};

export const Outcome = ({ location }) => {
  const { transactionId, token: transactionToken } = location?.search
    ? querystring.parse(location.search.substring(1))
    : {};

  const [state, setState] = useState({});

  const { title, description } = state?.transactionStatus
    ? MESSAGE_MAP[state.transactionStatus]
    : {};

  useEffect(() => {
    const fetchOutcome = async () => {
      if (!transactionId || !transactionToken) {
        return;
      }

      const outcome = await getOutcome(transactionId, transactionToken);
      setState(outcome);
    };

    fetchOutcome();
  }, [transactionId, transactionToken]);

  return (
    <AuthContext.Provider value={transactionToken}>
      <Grid preset="page" rowsGap={8}>
        <Grid.Item colSpan="all" colSpan-lg="0-11" rowSpan-lg="1-9">
          <Container>
            {state?.transactionStatus && (
              <Stack align="center" gap="4">
                <Stack>
                  <Text as="p" align="center" textStyle="heading3">
                    {title}
                  </Text>
                  <Text textStyle="subtitle1">{description}</Text>
                </Stack>

                <Stack align="center" gap="8">
                  <Message
                    severity="info-or-minor"
                    bg="secondary.lightBlue.t25"
                  >
                    Please contact merchant and quote following reference.
                  </Message>
                  <Text align="center">Reference: {state?.transactionId}</Text>
                </Stack>

                <Link
                  appearance="secondary-button"
                  variant="blue-button"
                  href={state?.urlCancel}
                  newTab={false}
                >
                  Back to Cart
                </Link>
              </Stack>
            )}
          </Container>
        </Grid.Item>
      </Grid>
    </AuthContext.Provider>
  );
};

export default Outcome;
